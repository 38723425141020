import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import NavigationMenu from './NavigationMenu';
import { Link } from 'react-router-dom';
import "../Components/Styles/Header.scss"

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const closeMenuOnOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeMenuOnOutsideClick);

    return () => {
      document.removeEventListener('click', closeMenuOnOutsideClick);
    };
  }, []);

  const preventPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to={'/'}>
          <img src="img/new-logo.png" alt="Logo" style={{ height: '60px', width: '60px' }} />
        </Link>
      </div>
      <Link style = { {textDecoration:'none' , color:'black' }}to={'/'}> 
      <div className='site-name'>
        <div className='site-name-1'>PRG</div>
        <div className='site-name-2'>CARS</div>
      </div>
      
      </Link>

      <div className='header-menu'>
        <div className='menu-item'>
            <Link to={'/'}>Home</Link>
        </div>
        <div className='menu-item'>
        <Link to={'/sellcar'}>Sell your car </Link>
        </div>
        <div className='menu-item'>
        <Link to={'/about'}>About Us</Link>
        </div>
        <div className='menu-item'>
        <Link to={'/testimonials'}>Our happy Customers </Link>
        </div>
      </div>
      
      <div className="menu-icon menu-icon-large" onClick={(e) => { toggleMenu(); preventPropagation(e); }}>
        {menuOpen ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faBars} />
        )}
      </div>
      <div ref={menuRef}>
        <NavigationMenu isOpen={menuOpen} toggleMenu={toggleMenu} />
      </div>
    </header>
  );
}

export default Header;
