import React from 'react';
import { faCalendarDay , faMoneyBill , faCircleCheck, faBook, faTruckPickup, faCheck} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../Components/Styles/Warranty.scss"

function Warranty() {
  return (
    <section className='warranty-main'>
      <div className='warranty-heading'>
        <h2>Benefits & AddOns</h2>
      </div>
      <div className='warranty-points'>
        <div className='warranty-photo'>
          <FontAwesomeIcon icon={faCalendarDay} />
        </div>
        <div className='warranty-details'>
          <h1>12 / 24 Months Warranty</h1>
          <p>Complete after-sales support on all covered parts for a stress-free drive</p>
        </div>
      </div>
      <div className='warranty-points'>
        <div className='warranty-photo'>
          <FontAwesomeIcon icon={faMoneyBill} />
        </div>
        <div className='warranty-details'>
          <h1>Easy Financing Options</h1>
          <p>Become eligible for Minimum Down Payment via EMIs of up to 60 months and instant loans</p>
        </div>
      </div>
      <div className='warranty-points'>
        <div className='warranty-photo'>
          <FontAwesomeIcon icon={faCircleCheck} />
        </div>
        <div className='warranty-details'>
          <h1>140 Quality Checks</h1>
          <p>Inspected across 140 parameters and refurbished by auto-experts, our cars are ready for the road</p>
        </div>
      </div>
      <div className='warranty-points-elarged'>
        <div className='warranty-photo'>
          <FontAwesomeIcon icon={faTruckPickup} />
        </div>
        <div className='warranty-details'>
          <div className='coverage-heading'>
              <h1>Road Side Assistance</h1>
          </div>
          <div className='coverage-points'>
              <div className='coverage-points-div'>
                <div className='coverage-point'>
                    <p> <FontAwesomeIcon icon={faCheck} style={{color:'green', marginRight:'0.5rem'}} /> Fuel Delivery</p>
                </div>
                <div className='coverage-point'>
                    <p> <FontAwesomeIcon icon={faCheck} style={{color:'green', marginRight:'0.5rem'}} />FlatTire Support</p>
                </div>
                <div className='coverage-point'>
                    <p> <FontAwesomeIcon icon={faCheck} style={{color:'green', marginRight:'0.5rem'}} /> BreakDown support over phone</p>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className='warranty-points-elarged'>
        <div className='warranty-photo'>
          <FontAwesomeIcon icon={faBook}/>
        </div>
        <div className='warranty-details'>
          <div className='coverage-heading'>
              <h1>Warranty Coverage</h1>
          </div>
          <div className='coverage-points'>
              <div className='coverage-points-div'>
                <div className='coverage-point'>
                    <p> <FontAwesomeIcon icon={faCheck} style={{color:'green', marginRight:'0.5rem'}} /> Engine & peripherals</p>
                </div>
                <div className='coverage-point'>
                    <p> <FontAwesomeIcon icon={faCheck} style={{color:'green', marginRight:'0.5rem'}} /> Transmission</p>
                </div>
                <div className='coverage-point'>
                    <p> <FontAwesomeIcon icon={faCheck} style={{color:'green', marginRight:'0.5rem'}} /> Steering system</p>
                </div>
                <div className='coverage-point'>
                    <p> <FontAwesomeIcon icon={faCheck} style={{color:'green', marginRight:'0.5rem'}} /> Braking system</p>
                </div>
                <div className='coverage-point'>
                    <p> <FontAwesomeIcon icon={faCheck} style={{color:'green', marginRight:'0.5rem'}} /> Air conditioning</p>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Warranty;
