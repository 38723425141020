import React from 'react'
import { useEffect, useState } from 'react' 
import { db,storage } from '../Firebase'
import { useNavigate } from 'react-router-dom'
import { collection, onSnapshot } from 'firebase/firestore';

function Cars() {

  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const unsub = onSnapshot(collection(db,"cars") , 
    (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc)=>{
            list.push({id:doc.id , ...doc.data()})
        });
        setCars(list);
        setLoading(false);
    },(error) =>{
        console.log(error);
    })

    return()=>{
        unsub();
    }
  },[]);
  return (
    <div>
        {cars && cars.map((item)=>(
            <>
            <img src={item.img} alt="Used cars in Nagpur"/>
            <h1>{item.name} </h1>
            <h1>{item.year}</h1>
            </>
        ))} 
    </div>
  )


}

export default Cars