import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { db } from '../Firebase';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { doc, getDoc } from 'firebase/firestore';
import { Carousel } from 'react-carousel-minimal';
import { faGear, faGasPump, faGaugeSimpleHigh, faIndianRupeeSign, faUserLarge, faRoad, faKey, faShield, faPhone, faCoins, faSun, faCamera, faWheelchair, faLock, faTemperatureArrowUp, faMusic, faChair } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFontAwesomeLogoFull } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Footer from './Footer';
import Warranty from './Warranty';
import Loader from './Loader';
import { collection, addDoc } from 'firebase/firestore';
import "../Components/Styles/MoreDetails.scss"
import "../Components/Styles/Overlay.scss"



const ContactFormOverlay = ({ onClose }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'contacts'), {
        name: name,
        phone: phone,
        message: message
      });
      console.log('Document written with ID: ', docRef.id);
      // Clear form data
      setName('');
      setPhone('');
      setMessage('');
      onClose();
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className="overlay overlay-desktop">
      <div className="overlay-content">
        <div className='overlay-header'>
          <div className='overlay-text'>
            <p>Connect with us</p>
          </div>
          <div className='overlay-close'>
            <span className="close-button" 
              onClick={onClose}>
              &times;
            </span>
          </div>
        </div>
        
       
        <div className='overlay_form'>
        <form onSubmit={handleFormSubmit}>
          <div className='name-input'>
              <input
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
          </div>
          <div className='email-input'>
              <input
                type="tel"
                placeholder="Your Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
          </div>
          <div className='text-area-input'>
              <textarea
                placeholder="Your Requirement"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
          </div>
        
          <button className = 'overlay-submit-btn' type="submit">Submit</button>
          <div className='overlay-p'>
              our represetative will contact you shortly
          </div>
        </form>

        <div className='overlay-sell'>
            <hr></hr>
            <p> want to sell your old car ?</p>
            <Link to={'/sellcar'}>
              <button className = 'overlay-sellcar-submit-btn ' type="submit">Get a Free Quote</button>
            </Link>
        </div>  
        </div>

      </div>
    </div>
  );
};


const MoreDetails = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const { id } = useParams();
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  };

  const [documentData, setDocumentData] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [downPayment, setDownPayment] = useState(100000);
  const [interestRate, setInterestRate] = useState(8); // Default 8%
  const [emiTenure, setEmiTenure] = useState(60); // Default 12 months
  const [monthlyEMI, setMonthlyEMI] = useState(0);
  const [lowestEMI, setLowestEMI] = useState(0);


  useEffect(() => {
    if (documentData) {
      calculateEMI();
      calculateLowestEMI(documentData.veh_price);
    }
  }, [downPayment, interestRate, emiTenure, documentData]);

  const calculateEMI = () => {
    const stringWithCommas = documentData.veh_price;
    const stringWithoutCommas = stringWithCommas.replace(/,/g, '');
    const vehiclePrice = stringWithoutCommas || 0;
    const loanAmount = vehiclePrice - downPayment;
    const monthlyInterestRate = interestRate / 12 / 100;
    const tenureMonths = emiTenure;

    if (loanAmount <= 0 || monthlyInterestRate <= 0 || tenureMonths <= 0) {
      setMonthlyEMI(0);
      return;
    }

    const emi =
      (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, tenureMonths)) /
      (Math.pow(1 + monthlyInterestRate, tenureMonths) - 1);
    setMonthlyEMI(emi.toFixed(0));
  };

  const fetchData = async () => {
    try {
      const docRef = doc(db, 'cars', id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setDocumentData(data);
        if (data.img && data.img.length > 0) {
          setImageUrls(data.img);
        }
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleDownPaymentChange = (value) => {
    setDownPayment(Number(value));
  };

  const calculateLowestEMI = (price) => {
    // Convert vehicle price from string with commas to number
    const stringWithoutCommas = price.replace(/,/g, '');
    const vehiclePrice = Number(stringWithoutCommas);

    // Set the default parameters for the lowest EMI calculation
    const downPayment = vehiclePrice * 0.50; // 10% down payment
    const interestRate = 8; // Default 8% interest rate
    const tenureMonths = 60; // Maximum tenure of 60 months

    const loanAmount = vehiclePrice - downPayment;
    const monthlyInterestRate = interestRate / 12 / 100;

    // EMI formula
    const emi = (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, tenureMonths)) /
      (Math.pow(1 + monthlyInterestRate, tenureMonths) - 1);

    setLowestEMI(emi.toFixed(0)); // Set the lowest EMI value
  };


  return (
    <div>
      {documentData ? (
        <div className='vehicle-details'>
          <div className='top-column'>
            <div className="vehicle-images">
              {imageUrls.length > 0 ? (
                <Carousel
                  className="custom-carousel"
                  data={imageUrls.map((imgUrl) => ({ image: imgUrl }))}
                  time={2000}
                  radius="10px"
                  slideNumber={true}
                  slideNumberStyle={slideNumberStyle}
                  dots={true}
                  slideBackgroundColor="darkgrey"
                  slideImageFit="cover"
                  fullscreen="true"
                  style={{
                    textAlign: 'center',
                    margin: '10px 0px 0px 0px',
                    objectFit:'cover',
                    maxHeight:'none'
                  }}
                />
              ) : (
                <p>No images available</p>
              )}
            </div>
          </div>

          <div className='price-details-section'>
          <div className='car_name_details'> <p> {documentData.year} {documentData.name} {documentData.veh_model} </p> </div> 

            <div className='price-label'>
                <div><p>Asking Price</p></div>
                <div className='price-label-desclaimer'><p>Includes RC transfer, insurance, fixes, upgrades & more</p></div>
            </div>
            <div className='price-details'>
                 <div className='asking_price'>
                  <div className='asking-price-sign'>
                    <p><FontAwesomeIcon icon={faIndianRupeeSign}/></p>
                  </div>
                  <div className='asking-price-value'>
                    <p> {documentData.veh_price}</p> 
                  </div>
                  </div>
            </div>
            <div className='buttons-section'>
                <div className='call-us-button'> 
                    <button onClick={() => setShowContactForm(true)}>
                      <FontAwesomeIcon icon={faPhone} /> Call us
                    </button>
                </div>

                <div className='whatsapp-us-button'> 
                    <a href='https://api.whatsapp.com/send?phone=9766624663'>
                      <button>
                        <FontAwesomeIcon icon={faWhatsapp} />
                      </button>
                    </a>
                </div>
          </div>
            <div className='emi-details'>
                <p> <FontAwesomeIcon icon={faCoins} />  EMI starts from <FontAwesomeIcon icon={faIndianRupeeSign} /> {lowestEMI} /month</p>
            </div>
          </div>

          <div className='car-specs'>
              <div className='car-specs-heading'>
                <h2>Car Specifications</h2>
              </div>
              <div className='car-specs-body'>
                <div className='car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faGaugeSimpleHigh} /> Kms Driven </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_kms_driven} km </p> </div>
                </div>
                <div className='car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faGear} /> Transmission </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_transmission_type} </p> </div>
                </div>
                <div className='car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faUserLarge} /> Owners </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_ownership} Owner </p> </div>
                </div>
                <div className='car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faRoad} /> Reg. Number </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.reg_number}  </p> </div>
                </div>
                <div className='car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faKey} /> Spare Key </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_spare_key}  </p> </div>
                </div>
                <div className='car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faShield} /> Insaurance </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_insaurance_valid}</p> </div>
                </div>
                <div className='car-spec-item car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faShield} /> Engine Capacity </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_engine_capacity} CC </p> </div>
                </div>
                <div className='car-spec-item car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faLock} /> Central Locking </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_central_locking}</p> </div>
                </div>
                <div className='car-spec-item car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faMusic} /> Infotainment</p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_music_player}</p> </div>
                </div>
                <div className='car-spec-item car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faShield} /> Air Bags</p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_air_bag}</p> </div>
                </div>
                <div className='car-spec-item car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faShield} /> ABS </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_abs}</p> </div>
                </div>
                <div className='car-spec-item car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faTemperatureArrowUp} /> Climate Control </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_climate_control}</p> </div>
                </div>
                <div className='car-spec-item car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faSun} /> Sunroof </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_sunroof}</p> </div>
                </div>
                <div className='car-spec-item car-spec-item'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faChair} /> Seat Type </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_seat_type}</p> </div>
                </div>
                <div className='car-spec-item car-spec-item-remove-border'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faCamera} /> Parking Camera </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_reverse_camera}</p> </div>
                </div>
                <div className='car-spec-item car-spec-item-remove-border'>
                  <div className='car-spec-item-heading'><p> <FontAwesomeIcon icon={faKey} /> Key Type </p> </div>
                  <div className='car-spec-item-detail'><p> {documentData.veh_key_type}</p> </div>
                </div>
              </div>
          </div>

           {/* EMI Calculator */}

           <div className="emi-calculator">
            <div className='calculator-section'>
            <h2>EMI Calculator</h2>
              <div className='calculator-top-section'> 
                <div className='calculator-top-section-heading'><p>Down Payment</p></div>
                <div className="calculator-top-section-input-field">
                    <input
                      type="number"
                      value={downPayment}
                      onChange={(e) => handleDownPaymentChange(e.target.value)}
                    />
                  </div>
              </div>
              <div className='calculator-slider-section'> </div>
               <div className="slider">       
                <input
                    type="range"
                    min="50000"
                    step="10000"
                    max={(documentData.veh_price).replace(/,/g, '') || 1000000}
                    value={downPayment}
                    onChange={(e) => handleDownPaymentChange(e.target.value)}
                  />
                </div>
              <div className='calculator-bottom-section'> 
                  <div className='calculator-top-section-heading'><p><FontAwesomeIcon icon={faIndianRupeeSign}></FontAwesomeIcon> 50,000</p></div>
                  <div className='calculator-top-section-heading'><p><FontAwesomeIcon icon={faIndianRupeeSign}></FontAwesomeIcon> {documentData.veh_price}</p></div>
              </div>
          
              <div className='calculator-top-section'> 
                <div className='calculator-top-section-heading'><p>Loan Tenure</p></div>
                <div className="calculator-top-section-input-field">{emiTenure} Months</div>
              </div>
              <div className='calculator-slider-section'> </div>
                  <div className="slider">
                  <input
                    type="range"
                    min="12"
                    max="60"
                    step="6"
                    placeholder='4'
                    value={emiTenure}
                    onChange={(e) => setEmiTenure(Number(e.target.value))}
                  />
                </div>
              <div className='calculator-bottom-section'> 
                  <div className='calculator-top-section-heading'><p>12 Months</p></div>
                  <div className='calculator-top-section-heading'><p>60 Months</p></div>

              </div>
              <div className="emi-result">
                 <h2><FontAwesomeIcon icon={faIndianRupeeSign}/> {monthlyEMI} Per Month</h2>
              </div>
              <div className='descreation'>
                <p>*Rate of interest can vary subject to credit profile. Loan approval is at the sole discretion of the finance partner.<br/>
                    **Processing fee and other loan charges are not included. </p>
              </div>
            </div>
          </div>
          <div className='warrenty'>
                <Warranty />
          </div>

      <Footer />
      {showContactForm && (
        <ContactFormOverlay onClose={() => setShowContactForm(false)} />
      )}
    </div>
      )
        : (
          <Loader />
        )}
    </div>
  );
};

export default MoreDetails;